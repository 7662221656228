import { ss } from '@/plugins/storage'

export default {
  beforeRouteEnter(to, from, next) {
    const isMiniProgram = window.__wxjs_environment === 'miniprogram'
    if (!window.isWechat || isMiniProgram || from.name === null || from.name === to.name) {
      if (ss.getItem('routeReloaded')) {
        ss.removeItem('routeReloaded')
      }
      return next()
    }
    if (!ss.getItem('routeReloaded')) {
      console.log('Reloading')
      ss.setItem('routeReloaded', 1)
      setTimeout(() => {
        location.replace(to.fullPath)
      })
      return
    }
    ss.removeItem('routeReloaded')
    next()
  }
}
