module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}