/**
 * Created by chenzhuokai on 2017/2/7.
 */
import { mapGetters } from 'vuex'
import { isEqual } from 'lodash'

export default function (name, params) {
  const mixin = {}
  params = Object.assign({}, params)
  const {
    global,
    query: paramQuery,
    fallThrough,
    relativeParam,
    relativeQuery,
    lazy,
    alias = name
  } = params
  if (name) {
    const nameCapital = name.replace(/^(\w)/, $1 => $1.toUpperCase())

    let computed = {}
    const data = {
      loadStatus: 0
    }

    if (global) {
      computed = {
        ...mapGetters([name])
      }
    } else {
      let defaultData = {}
      if (/s$/.test(name)) {
        defaultData = []
      }
      data[alias] = data[alias] || defaultData
    }

    const fetchDataMethodName = `fetch${nameCapital}`
    const updateDataMethodName = `update${nameCapital}`
    const initDataMethodName = `init${nameCapital}`

    Object.assign(mixin, {
      computed: {
        ...computed,
        loading() {
          return this.loadStatus > 0
        }
      },
      data() {
        return data
      },
      methods: {
        [fetchDataMethodName](params) {
          const query = Object.assign({...paramQuery}, this.$route.params, this.$route.query, params)
          return this.$ajax[fetchDataMethodName](query)
        },
        [updateDataMethodName]() {
          return this[fetchDataMethodName](...arguments).catch(err => {
            if (fallThrough) {
              // 指定了 fallThrough 则将错误也设置到当前变量
              return err
            }
            return Promise.reject(err)
          }).then(data => {
            this.$set(this, alias, data)
            return data
          })
        },
        async [initDataMethodName]() {
          this.loadStatus++
          try {
            await this[updateDataMethodName]()
          } catch (err) {
            console.error('RouteLoadError', err)
            this.onRouteDataLoadError(err)
          } finally {
            this.$nextTick(() => {
              this.loadStatus--
              this.loadStatus = Math.max(this.loadStatus, 0)
            })
          }
        },
        onRouteDataLoadError(err) {
          if (err.errcode === 21003) {
            this.$store.commit('resetAuth')
            err.message = '您的登录信息无效或已过期，请重新登录后继续哦'
            err.to = '/signin?redirect=' + encodeURIComponent(this.$route.fullPath)
          }
          this.$throwError(err)
        }
      },
      async created() {
        if (relativeParam && !this.$route.params[relativeParam]) {
          return
        }
        await this[initDataMethodName]()
        if (!this.loadStatus) {
          this.onLoad?.()
          this.$options?.onLoad?.call?.(this)
        }
      },
      watch: {
        async '$route'(newVal, oldVal) {
          if (lazy) {
            return
          }
          if (relativeParam && isEqual(newVal.params[relativeParam], oldVal.params[relativeParam])) {
            return
          }
          if (relativeQuery && isEqual(newVal.query[relativeQuery], oldVal.query[relativeQuery])) {
            return
          }
          await this[initDataMethodName]()
          if (!this.loadStatus) {
            this?.onLoad?.()
            this.$options?.onLoad?.call?.(this)
          }
        }
      }
    })
  }
  return mixin
}
