module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 var tzOffset = dateTime.getTimezoneOffset() ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (tzOffset + 480) * 60 * 1000) ;
__p += '\n';
 var day = ('0' + dateTime.getDate()).slice(-2); ;
__p += '\n';
 var weekday = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][dateTime.getDay()]; ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n<div class="label" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'" data-time="' +
((__t = ( dateTime )) == null ? '' : __t) +
'"\n     style="top: ' +
((__t = ( element.y + yOrigin )) == null ? '' : __t) +
'px; left: ' +
((__t = ( element.x + xOrigin )) == null ? '' : __t) +
'px">\n  <div class="pill">\n    <span class="date">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n    <span class="day">' +
((__t = ( weekday )) == null ? '' : __t) +
'</span>\n  </div>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}