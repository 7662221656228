module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 element.content.forEach(function (line) { ;
__p += '\n  <g class="text" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'">\n    ';
 var chars = line.text ;
__p += '\n    ';
 for (var m = 0; m < chars.length; m++) { ;
__p += '\n      ';
 var x = xOrigin + line.x[m] ;
__p += '\n      ';
 var y = yOrigin + line.y ;
__p += '\n      ';
 if (line.emoji && line.emoji[m]) { ;
__p += '\n        ';
 var emoji = line.emoji[m] ;
__p += '\n        ';
 var isUrl = /^http/.test(emoji) ;
__p += '\n        ';
 emoji = isUrl ? emoji : 'https://static.weixinshu.com/assets/emotions/' + emoji ;
__p += '\n        <image xlink:href="' +
((__t = ( emoji )) == null ? '' : __t) +
'" width="18" height="18" x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'"/>\n      ';
 } else if (line.x[m] >= 0) { ;
__p += '\n        <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y + 14 )) == null ? '' : __t) +
'">' +
((__t = ( chars[m] )) == null ? '' : __t) +
'</text>\n      ';
 } ;
__p += '\n    ';
 } ;
__p += '\n  </g>\n';
 }) ;
__p += '\n';

}
return __p
}