module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 function getStyle(styles) {
  return Object
    .keys(styles)
    .filter(function (k) {
      return styles[k]
    })
    .map(function (k) {
      var unit = ''
      if (['top', 'left', 'width', 'height'].indexOf(k) > -1) {
        unit = 'px'
      }
      return k + ':' + styles[k] + unit + ';'
    }).join('')
} ;
__p += '\n\n';
 function dateDiff(start, end) {
  start = new Date(start)
  var startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  if (!end) {
    end = new Date();
  }
  end = new Date(end)
  // need date in YYYY-MM-DD format
  var endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  if (startDate > endDate) {
    return null
  }

  var startYear = startDate.getFullYear();

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    var year = endDate.getFullYear()
    var february = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var lastMonth = endDate.getMonth() - 1
    if (lastMonth < 0) {
      lastMonth = 11
    }
    dayDiff += daysInMonth[lastMonth];
  }

  var oneDay = 86400000
  if (endDate - startDate < oneDay * 100) {
    dayDiff = Math.ceil((endDate - startDate) / oneDay)
    dayDiff++
    if (dayDiff === 1) {
      return '出生啦'
    }
    if (dayDiff === 100) {
      return '100天啦'
    }
    return dayDiff + '天'
  }

  if (yearDiff > 0 && monthDiff === 0 && dayDiff === 0) {
    return yearDiff + '岁啦'
  }

  if (yearDiff > 0) {
    dayDiff = 0
  }

  return [
    yearDiff ? yearDiff + '岁' : '',
    monthDiff ? monthDiff + '个月' : '',
    dayDiff ? dayDiff + '天' : ''
  ].join('')
} ;
__p += '\n\n';
 function ifTextByChars(elements) {
  return JSON.stringify(elements).indexOf('"x":[') > -1
} ;
__p += '\n\n';
 var xOrigin = 0, yOrigin = 0 ;
__p += '\n';
 var titles = page.elements.filter(function (el) {
  return /^title/.test(el.type)
}) ;
__p += '\n';
 var subtitles = page.elements.filter(function (el) {
  return /^subtitle/.test(el.type)
}) ;
__p += '\n';
 var ps = page.elements.filter(function (el) {
  return /p|text|date/.test(el.type)
}) ;
__p += '\n';
 var images = page.elements.filter(function (el) {
  return /image|qrcode|video/.test(el.type)
}) ;
__p += '\n';
 var catalogs = page.elements.filter(function (el) {
  return /catalog/.test(el.type)
}) ;
__p += '\n';
 var notes = page.elements.filter(function (el) {
  return /note/.test(el.type)
}) ;
__p += '\n\n';
 if (images.length) { ;
__p += '\n<div class="images">\n  ';
 images.forEach(function(element) { ;
__p += '\n  ';
 var src = element.src || element.content || '' ;
__p += '\n';
 var originalSrc = src ;
__p += '\n';
 var style = getStyle({
  top: element.y + yOrigin,
  left: element.x + xOrigin,
  width: element.w,
  height: element.h
}) ;
__p += '\n';
 var encode = function (str) {
  return btoa(unescape(encodeURIComponent(str))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
} ;
__p += '\n';
 var validSizes = [320, 480, 640, 800, 960, 1280] ;
__p += '\n';
 var size = validSizes.find(function (s) {
  return s >= element.w
}) || 640 ;
__p += '\n\n';
 if (element.type === 'video' && !element.video) {
  element.type = 'image'
} ;
__p += '\n';
 if (element.type === 'qrcode' && /^\/media/.test(src)) {
  element.type = 'image'
} ;
__p += '\n\n';
 if (element.type === 'image') { ;
__p += '\n  ';
 var isSelfHosted = /^\/(media|upload|fetch)|(img|static|cdn)\.xinshu\.me/.test(src) ;
__p += '\n  ';
 var suffix = '!' + (window.canUseWebp ? 'webp' : '') + size ;
__p += '\n  ';
 if (isSelfHosted) {
    src = src.split(/[#?!]/)[0].replace(/.*\/(upload|fetch)/, 'https://img.xinshu.me/$1') + suffix
  } else {
    src = 'https://media2.xinshu.me/fetch/' + encode(src) + suffix
  } ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'qrcode' && originalSrc && !/^\/(media|upload)/.test(originalSrc)) { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video?url=' +
    encodeURIComponent(originalSrc.split('#')[0]) ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'video') { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video-2?pic=' +
    encodeURIComponent(element.thumbnail)
    + '&qrcode=' + encodeURIComponent(element.video) ;
__p += '\n';
 } ;
__p += '\n';
 var classes = ['image', element.type].join(' ') ;
__p += '\n\n<div class="' +
((__t = ( classes )) == null ? '' : __t) +
'" data-msg-id="' +
((__t = ( element.msgId || element.pid )) == null ? '' : __t) +
'" style="' +
((__t = ( style )) == null ? '' : __t) +
'"\n     lazy-load="' +
((__t = ( src )) == null ? '' : __t) +
'"></div>\n\n  ';
 }) ;
__p += '\n</div>\n';
 } ;
__p += '\n\n<svg class="blogbook">\n  ';
 if (titles.length) { ;
__p += '\n  ';
 titles.forEach(function (element) { ;
__p += '\n  ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n  ';
 }) ;
__p += '\n  ';
 } ;
__p += '\n\n  ';
 if (subtitles.length) { ;
__p += '\n  ';
 subtitles.forEach(function (element) { ;
__p += '\n  ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n  ';
 }) ;
__p += '\n  ';
 } ;
__p += '\n\n  ';
 if (notes.length) { ;
__p += '\n  ';
 notes.forEach(function (element) { ;
__p += '\n  ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n  ';
 }) ;
__p += '\n  ';
 } ;
__p += '\n\n  ';
 if (ps.length) { ;
__p += '\n  ';
 ps.forEach(function (element) { ;
__p += '\n  ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n  ';
 }) ;
__p += '\n  ';
 } ;
__p += '\n\n  ';
 if (catalogs.length) { ;
__p += '\n  ';
 catalogs.forEach(function (element) { ;
__p += '\n  ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var style = getStyle({
    'font-size': element.fontSize || null,
    // fill: element.color || null,
    // 'font-weight': element.fontWeight || null,
    // 'font-style': element.fontStyle || null
  }) ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    <g class="' +
((__t = ( element.type || '' )) == null ? '' : __t) +
'"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n      ';
 var y = line.y + 14 ;
__p += '\n      ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n      ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n        ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n        ';
 if (emoji) { ;
__p += '\n          ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n          ';
 if (!/^https?:/.test(link)) {
            link = 'https://static.weixinshu.com/assets/emotions/' + link
          } ;
__p += '\n          <image x="' +
((__t = ( line.x[l] + 2 )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'" xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"\n                 width="18" height="18"/>\n          ';
 continue ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n          ';
 var x = line.x[l], char = chars[l] ;
__p += '\n          <text x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n  ';
 }) ;
__p += '\n  ';
 } ;
__p += '\n</svg>\n\n';
 if (catalogs.length === 0) { ;
__p += '\n<footer>\n  <div class="pagination" style="font-size: ' +
((__t = ( pageFont.fontSize )) == null ? '' : __t) +
'px">' +
((__t = ( pageNo )) == null ? '' : __t) +
'</div>\n</footer>\n';
 } ;
__p += '\n';

}
return __p
}