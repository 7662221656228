<template>
  <div class="page copyright-page" :class="[book.type]" id="copyright">
    <div class="inner">
      <section class="cip">
        <p>
          <b><span class="publisher">{{publisher}}</span>在版编目数据</b>
        </p>
        <p>
          <span class="title">{{book.title}}</span> / <span class="author">{{book.author}}</span> 著.
          — <span class="company">{{company}}</span>,
          {{now | moment('YYYY.M')}}
        </p>
        <p v-if="!editing">{{xsbn}}</p>
        <p v-if="!editing">
          Ⅰ. {{Array.from(book.title)[0]}}…&#12288;
          Ⅱ. <span class="author">{{Array.from(book.author)[0]}}…</span>&#12288;
          Ⅲ. 文学随笔—{{product}}—中国—现代&#12288;
          Ⅳ. {{book.type.toUpperCase().split('-').join('.')}}
        </p>
        <p>
          <span class="publisher">{{publisher}}</span>版本图书馆数据核字
          ({{now | moment('YYYY')}}) 第 {{book.id}} 号
        </p>
      </section>

      <section class="mt-auto">
        <h4 class="book-title">
          <span class="title">{{book.title}}</span>
        </h4>
        <hr>
        <table>
          <tbody>
          <tr>
            <td>作&#12288;&#12288;者</td>
            <td>
              <span class="author">{{book.author}}</span>
            </td>
          </tr>
          <tr>
            <td>责任编辑</td>
            <td>
              <span class="editor">{{editor}}</span>
            </td>
          </tr>
          <tr>
            <td>装帧设计</td>
            <td>
              <span class="designer">{{designer}}</span>
            </td>
          </tr>
          <tr>
            <td>出版发行</td>
            <td>
              <span class="company">{{company}}</span>
            </td>
          </tr>
          <tr>
            <td>网&#12288;&#12288;址</td>
            <td>
              <span class="link">{{link}}</span>
            </td>
          </tr>
          <tr>
            <td>电&#12288;&#12288;话</td>
            <td>
              <span class="telephone">{{telephone}}</span>
            </td>
          </tr>
          <tr>
            <td>开&#12288;&#12288;本</td>
            <td v-if="typeset.includes('A4')">210mm × 285mm</td>
            <td v-else>148mm × 210mm</td>
          </tr>
          <tr>
            <td>版&#12288;&#12288;次</td>
            <td>
              <span class="edition">{{edition}}</span>
            </td>
          </tr>
          <tr>
            <td>编&#12288;&#12288;号</td>
            <td>{{xsbn}}</td>
          </tr>
          <tr v-if="!editing">
            <td>定&#12288;&#12288;价</td>
            <td>无价</td>
          </tr>
          </tbody>
        </table>
        <template v-if="!editing">
          <hr>
          <p>
            本书版权归书中内容原创者所有，未经原作者许可，不得转载、复制、翻印，一切因版权、著作权、肖像权、名誉权、姓名权而引起的法律纠纷由本书制作者承担。
          </p>
        </template>
      </section>
    </div>

    <div class="mask" v-if="$slots.default && book.editable">
      <slot/>
    </div>
  </div>
</template>

<script>
import { get, toUpper } from 'lodash'
import bookUtils from '@/mixins/book-utils'
import { bookProducts } from '@/config'

export default {
  name: 'copyrightPage',
  mixins: [bookUtils],
  props: {
    book: {
      type: Object,
      required: true
    },
    editing: Boolean
  },
  data() {
    return {
      now: new Date()
    }
  },
  computed: {
    typeset() {
      return this.book.type || ''
    },
    bookType() {
      return toUpper(this.book.bookType)
    },
    agentInfo() {
      return this.book.agentInfo?.supplementary || {}
    },
    copyright() {
      return this.book.style.copyright || {}
    },
    product() {
      return get(bookProducts, [this.book.bookType, 1]) || '心书'
    },
    xsbn() {
      return `XSBN-${this.bookType}-${toUpper(this.book.id)}`
    },
    publisher() {
      return this.copyright.publisher || this.agentInfo.brand || '心书'
    },
    designer() {
      return this.copyright.designer || this.agentInfo.designer || '心书设计'
    },
    editor() {
      return this.copyright.editor || this.agentInfo.editor || '心书小编'
    },
    company() {
      return this.copyright.company || this.agentInfo.companyName || '杭州心书网络科技有限公司'
    },
    link() {
      return this.copyright.link || this.agentInfo.companyLink || 'weixinshu.com'
    },
    telephone() {
      return this.copyright.telephone || this.agentInfo.companyPhone || '400-881-9593'
    },
    edition() {
      return this.copyright.edition || this.$day().format('YYYY年M月第1版')
    }
  }
}
</script>

<style scoped lang="scss">
.copyright-page {
  text-align: left;
  line-height: 1.25;
  font-size: 14px;
  padding: 42px 56px 52px;

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &.font-big {
    font-size: 16px;

    .book-title {
      font-size: 20px;
    }
  }

  &.font-max {
    font-size: 18px;

    .book-title {
      font-size: 22px;
    }
  }

  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: $font-family-serif;
  }

  table {
    width: 100%;

    td {
      padding: 0;
      height: 1.5em;
      padding-top: .25em;
      padding-bottom: .25em;

      &:first-child {
        width: 6em;
      }
    }
  }

  .book-title {
    font-size: 18px;
    font-weight: bold;
  }

  hr {
    border-color: #666;
  }

  p {
    margin-top: 0;
  }
}
</style>
