module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var src = element.url ;
__p += '\n';
 var style = getStyle({
  top: element.y + yOrigin,
  left: element.x + xOrigin,
  width: element.w,
  height: element.h,
  'background-image': 'url(' + src + '!640)'
}) ;
__p += '\n<div class="decoration" style="' +
((__t = ( style )) == null ? '' : __t) +
'"></div>\n';

}
return __p
}