<template>
  <div class="page insert-page" :class="[book.type]">
    <transition name="fade">
      <img :src="image" alt="" v-if="status > 0" draggable="false">
    </transition>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'insertPage',
  props: {
    date: {
      type: String,
      required: true
    },
    book: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: 0
    }
  },
  created() {
    this.loadImage(this.image).then(() => {
      this.status = 1
    }).catch(() => {
      this.status = -1
    })
  },
  computed: {
    image() {
      const year = this.date.slice(0, 4)
      const month = parseInt(this.date.slice(-2))
      const codeName = this.book?.style?.insertPage || 'season'
      return `https://canvas.xinshu.me/generate/insert-${codeName}.${month}` + this.serialize({
        year,
        size: /A4/i.test(this.book.type) ? 800 : 640
      })
    },
    style() {
      if (!this.status) {
        return null
      }
      return {
        'background-image': 'url(' + this.image + ')'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.insert-page {
  background-color: #fff;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 2em;
  }
}
</style>
