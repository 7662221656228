module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var tzOffset = new Date().getTimezoneOffset() ;
__p += '\n';
 var dateTime = new Date(element.content * 1000 + (tzOffset + 480) * 60 * 1000); ;
__p += '\n';
 var week = dateTime.getDay() ;
__p += '\n';
 var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'] ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 function getStyle(styles) {
  return Object
    .keys(styles)
    .filter(function (k) {
      return styles[k]
    })
    .map(function (k) {
      var unit = ''
      if (['top', 'left', 'width', 'height'].indexOf(k) > -1) {
        unit = 'px'
      }
      return k + ':' + styles[k] + unit + ';'
    }).join('')
} ;
__p += '\n\n';
 function dateDiff(start, end) {
  start = new Date(start)
  var startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  if (!end) {
    end = new Date();
  }
  end = new Date(end)
  // need date in YYYY-MM-DD format
  var endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  if (startDate > endDate) {
    return null
  }

  var startYear = startDate.getFullYear();

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    var year = endDate.getFullYear()
    var february = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var lastMonth = endDate.getMonth() - 1
    if (lastMonth < 0) {
      lastMonth = 11
    }
    dayDiff += daysInMonth[lastMonth];
  }

  var oneDay = 86400000
  if (endDate - startDate < oneDay * 100) {
    dayDiff = Math.ceil((endDate - startDate) / oneDay)
    dayDiff++
    if (dayDiff === 1) {
      return '出生啦'
    }
    if (dayDiff === 100) {
      return '100天啦'
    }
    return dayDiff + '天'
  }

  if (yearDiff > 0 && monthDiff === 0 && dayDiff === 0) {
    return yearDiff + '岁啦'
  }

  if (yearDiff > 0) {
    dayDiff = 0
  }

  return [
    yearDiff ? yearDiff + '岁' : '',
    monthDiff ? monthDiff + '个月' : '',
    dayDiff ? dayDiff + '天' : ''
  ].join('')
} ;
__p += '\n\n';
 function ifTextByChars(elements) {
  return JSON.stringify(elements).indexOf('"x":[') > -1
} ;
__p += '\n\n';
 var style = getStyle({top: element.y, left: element.x, width: element.w}) ;
__p += '\n<div class="label" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'" data-time="' +
((__t = ( dateTime )) == null ? '' : __t) +
'" style="' +
((__t = ( style )) == null ? '' : __t) +
'">\n  <div class="pill">\n    <span class="date">' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</span>\n    <span class="day">' +
((__t = ( weeks[week] )) == null ? '' : __t) +
'</span>\n  </div>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}