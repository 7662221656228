module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var src = element.src || element.content || '' ;
__p += '\n';
 var originalSrc = src ;
__p += '\n';
 var style = getStyle({
  top: element.y + yOrigin,
  left: element.x + xOrigin,
  width: element.w,
  height: element.h
}) ;
__p += '\n';
 var encode = function (str) {
  return btoa(unescape(encodeURIComponent(str))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
} ;
__p += '\n';
 var validSizes = [320, 480, 640, 800, 960, 1280] ;
__p += '\n';
 var size = validSizes.find(function (s) {
  return s >= element.w
}) || 640 ;
__p += '\n\n';
 if (element.type === 'video' && !element.video) {
  element.type = 'image'
} ;
__p += '\n';
 if (element.type === 'qrcode' && /^\/media/.test(src)) {
  element.type = 'image'
} ;
__p += '\n\n';
 if (element.type === 'image') { ;
__p += '\n  ';
 var isSelfHosted = /^\/(media|upload|fetch)|(img|static|cdn)\.xinshu\.me/.test(src) ;
__p += '\n  ';
 var suffix = '!' + (window.canUseWebp ? 'webp' : '') + size ;
__p += '\n  ';
 if (isSelfHosted) {
    src = src.split(/[#?!]/)[0].replace(/.*\/(upload|fetch)/, 'https://img.xinshu.me/$1') + suffix
  } else {
    src = 'https://media2.xinshu.me/fetch/' + encode(src) + suffix
  } ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'qrcode' && originalSrc && !/^\/(media|upload)/.test(originalSrc)) { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video?url=' +
    encodeURIComponent(originalSrc.split('#')[0]) ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'video') { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video-2?pic=' +
    encodeURIComponent(element.thumbnail)
    + '&qrcode=' + encodeURIComponent(element.video) ;
__p += '\n';
 } ;
__p += '\n';
 var classes = ['image', element.type].join(' ') ;
__p += '\n\n<div class="' +
((__t = ( classes )) == null ? '' : __t) +
'" data-msg-id="' +
((__t = ( element.msgId || element.pid )) == null ? '' : __t) +
'" style="' +
((__t = ( style )) == null ? '' : __t) +
'"\n     lazy-load="' +
((__t = ( src )) == null ? '' : __t) +
'"></div>\n';

}
return __p
}