module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 function getStyle(styles) {
  return Object
    .keys(styles)
    .filter(function (k) {
      return styles[k]
    })
    .map(function (k) {
      var unit = ''
      if (['top', 'left', 'width', 'height'].indexOf(k) > -1) {
        unit = 'px'
      }
      return k + ':' + styles[k] + unit + ';'
    }).join('')
} ;
__p += '\n\n';
 function dateDiff(start, end) {
  start = new Date(start)
  var startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  if (!end) {
    end = new Date();
  }
  end = new Date(end)
  // need date in YYYY-MM-DD format
  var endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  if (startDate > endDate) {
    return null
  }

  var startYear = startDate.getFullYear();

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    var year = endDate.getFullYear()
    var february = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var lastMonth = endDate.getMonth() - 1
    if (lastMonth < 0) {
      lastMonth = 11
    }
    dayDiff += daysInMonth[lastMonth];
  }

  var oneDay = 86400000
  if (endDate - startDate < oneDay * 100) {
    dayDiff = Math.ceil((endDate - startDate) / oneDay)
    dayDiff++
    if (dayDiff === 1) {
      return '出生啦'
    }
    if (dayDiff === 100) {
      return '100天啦'
    }
    return dayDiff + '天'
  }

  if (yearDiff > 0 && monthDiff === 0 && dayDiff === 0) {
    return yearDiff + '岁啦'
  }

  if (yearDiff > 0) {
    dayDiff = 0
  }

  return [
    yearDiff ? yearDiff + '岁' : '',
    monthDiff ? monthDiff + '个月' : '',
    dayDiff ? dayDiff + '天' : ''
  ].join('')
} ;
__p += '\n\n';
 function ifTextByChars(elements) {
  return JSON.stringify(elements).indexOf('"x":[') > -1
} ;
__p += '\n\n';
 var xOrigin = 0, yOrigin = 0 ;
__p += '\n';
 var texts = page.elements.filter(function (el) {
  return el.type === 'text'
}) ;
__p += '\n';
 var labels = page.elements.filter(function (el) {
  return el.type === 'label'
}) ;
__p += '\n';
 var images = page.elements.filter(function (el) {
  return /image|qrcode|video/.test(el.type)
}) ;
__p += '\n\n';
 if (page.decorations && page.decorations.length) { ;
__p += '\n  <div class="decorations">\n    ';
 page.decorations.forEach(function (element) { ;
__p += '\n      ';
 var src = element.url ;
__p += '\n';
 var style = getStyle({
  top: element.y + yOrigin,
  left: element.x + xOrigin,
  width: element.w,
  height: element.h,
  'background-image': 'url(' + src + '!640)'
}) ;
__p += '\n<div class="decoration" style="' +
((__t = ( style )) == null ? '' : __t) +
'"></div>\n\n    ';
 }) ;
__p += '\n  </div>\n';
 } ;
__p += '\n\n';
 if (labels.length) { ;
__p += '\n  <div class="labels">\n    ';
 labels.forEach(function (element) { ;
__p += '\n      ';
 if (book.style.baby && book.style.baby.enable) { ;
__p += '\n        ';
 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (dateTime.getTimezoneOffset() + 480) * 60000) ;
__p += '\n';
 var birthday = new Date(book.style.baby.birthday) ;
__p += '\n';
 birthday = new Date(birthday.getTime() + (dateTime.getTimezoneOffset() + 480) * 60000) ;
__p += '\n';
 var week = dateTime.getDay() ;
__p += '\n';
 var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'] ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 var style = getStyle({top: element.y, left: element.x, width: element.w}) ;
__p += '\n';
 var days = dateDiff(birthday, dateTime);
__p += '\n<div style="' +
((__t = ( style )) == null ? '' : __t) +
'" class="label label-baby" data-content="' +
((__t = ( element.content )) == null ? '' : __t) +
'">\n  ';
 if (days) { ;
__p += '\n    <div class="days">\n      <span class="before"></span>\n      <span class="content">\n        ' +
((__t = ( book.style.baby.nickname + days )) == null ? '' : __t) +
'\n      </span>\n      <span class="after"></span>\n    </div>\n  ';
 } ;
__p += '\n  <div class="board">\n    <span class="date">' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</span>\n    <span class="day">' +
((__t = ( weeks[week] )) == null ? '' : __t) +
'</span>\n  </div>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n\n      ';
 } else { ;
__p += '\n        ';
 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 var tzOffset = dateTime.getTimezoneOffset() ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (tzOffset + 480) * 60 * 1000) ;
__p += '\n';
 var week = dateTime.getDay() ;
__p += '\n';
 var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'] ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 var style = getStyle({top: element.y, left: element.x, width: element.w}) ;
__p += '\n<div style="' +
((__t = ( style )) == null ? '' : __t) +
'" class="label">\n  <div class="board">\n    <span class="date">' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</span>\n    <span class="day">' +
((__t = ( weeks[week] )) == null ? '' : __t) +
'</span>\n  </div>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n\n      ';
 } ;
__p += '\n    ';
 }) ;
__p += '\n  </div>\n';
 } ;
__p += '\n\n';
 if (images.length) { ;
__p += '\n  <div class="images">\n    ';
 images.forEach(function (element) { ;
__p += '\n      ';
 var src = element.src || element.content || '' ;
__p += '\n';
 var originalSrc = src ;
__p += '\n';
 var style = getStyle({
  top: element.y + yOrigin,
  left: element.x + xOrigin,
  width: element.w,
  height: element.h
}) ;
__p += '\n';
 var encode = function (str) {
  return btoa(unescape(encodeURIComponent(str))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
} ;
__p += '\n';
 var validSizes = [320, 480, 640, 800, 960, 1280] ;
__p += '\n';
 var size = validSizes.find(function (s) {
  return s >= element.w
}) || 640 ;
__p += '\n\n';
 if (element.type === 'video' && !element.video) {
  element.type = 'image'
} ;
__p += '\n';
 if (element.type === 'qrcode' && /^\/media/.test(src)) {
  element.type = 'image'
} ;
__p += '\n\n';
 if (element.type === 'image') { ;
__p += '\n  ';
 var isSelfHosted = /^\/(media|upload|fetch)|(img|static|cdn)\.xinshu\.me/.test(src) ;
__p += '\n  ';
 var suffix = '!' + (window.canUseWebp ? 'webp' : '') + size ;
__p += '\n  ';
 if (isSelfHosted) {
    src = src.split(/[#?!]/)[0].replace(/.*\/(upload|fetch)/, 'https://img.xinshu.me/$1') + suffix
  } else {
    src = 'https://media2.xinshu.me/fetch/' + encode(src) + suffix
  } ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'qrcode' && originalSrc && !/^\/(media|upload)/.test(originalSrc)) { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video?url=' +
    encodeURIComponent(originalSrc.split('#')[0]) ;
__p += '\n';
 } ;
__p += '\n\n';
 if (element.type === 'video') { ;
__p += '\n  ';
 src = 'https://canvas.xinshu.me/generate/wxbook-video-2?pic=' +
    encodeURIComponent(element.thumbnail)
    + '&qrcode=' + encodeURIComponent(element.video) ;
__p += '\n';
 } ;
__p += '\n';
 var classes = ['image', element.type].join(' ') ;
__p += '\n\n<div class="' +
((__t = ( classes )) == null ? '' : __t) +
'" data-msg-id="' +
((__t = ( element.msgId || element.pid )) == null ? '' : __t) +
'" style="' +
((__t = ( style )) == null ? '' : __t) +
'"\n     lazy-load="' +
((__t = ( src )) == null ? '' : __t) +
'"></div>\n\n    ';
 }) ;
__p += '\n  </div>\n';
 } ;
__p += '\n\n';
 if (texts.length) { ;
__p += '\n  ';
 if (ifTextByChars(texts)) { ;
__p += '\n    <svg class="texts">\n      ';
 texts.forEach(function (element) { ;
__p += '\n        ';
 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    ';
 var fontSize = element.fontSize || 14 ;
__p += '\n    ';
 var y = line.y + fontSize ;
__p += '\n    <g class="text ' +
((__t = ( line.isComment ? 'comments' : '' )) == null ? '' : __t) +
'"\n       style="font-size: ' +
((__t = ( fontSize )) == null ? '' : __t) +
'px"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'">\n      ';
 if (line.commentStat) { ;
__p += '\n        ';
 var thumbsCount = line.commentStat.split(',')[0] ;
__p += '\n        ';
 var commentsCount = line.commentStat.split(',')[1] ;
__p += '\n        ';
 var offset = thumbsCount.toString().length * fontSize + fontSize ;
__p += '\n        <image x="' +
((__t = ( line.x[0] )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y + 1 )) == null ? '' : __t) +
'"\n               width="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'"\n               xlink:href="https://img.xinshu.me/upload/84fb37deb2f74c6a8c65d95e3a5180fd"/>\n        <text class="text theme"\n              x="' +
((__t = ( line.x[0] + fontSize * 1.5 )) == null ? '' : __t) +
'"\n              y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( thumbsCount )) == null ? '' : __t) +
'</text>\n        <image x="' +
((__t = ( line.x[0] + fontSize + offset )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y + 1 )) == null ? '' : __t) +
'"\n               width="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'"\n               xlink:href="https://img.xinshu.me/upload/12cee2aa10c3474b83830ed1341ed893"/>\n        <text class="text theme"\n              x="' +
((__t = ( line.x[0] + fontSize * 2.5 + offset )) == null ? '' : __t) +
'"\n              y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( commentsCount )) == null ? '' : __t) +
'</text>\n      ';
 } else { ;
__p += '\n        ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n        ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n          ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n          ';
 if (emoji) { ;
__p += '\n            ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n            ';
 if (!/^https?:/.test(link)) {
              link = 'https://static.weixinshu.com/assets/emotions/' + link
            } ;
__p += '\n            <image x="' +
((__t = ( line.x[l] )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'"\n                   width="' +
((__t = ( fontSize + 4 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 4 )) == null ? '' : __t) +
'"\n                   xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"/>\n            ';
 continue ;
__p += '\n          ';
 } ;
__p += '\n          ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n            ';
 var x = line.x[l], char = chars[l] ;
__p += '\n            ';
 var classes = [] ;
__p += '\n            ';
 if (l <= line.commenterX) {
              classes.push('theme')
            } ;
__p += '\n            ';
 var classNames = classes.join(' ') ;
__p += '\n            <text class="' +
((__t = ( classNames )) == null ? '' : __t) +
'" x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n      ';
 }) ;
__p += '\n    </svg>\n  ';
 } else { ;
__p += '\n    <div class="texts">\n      ';
 texts.forEach(function (element) { ;
__p += '\n        ';
 function getStyle(styles) {
  return Object
    .keys(styles)
    .filter(function (k) {
      return styles[k]
    })
    .map(function (k) {
      var unit = ''
      if (['top', 'left', 'width', 'height'].indexOf(k) > -1) {
        unit = 'px'
      }
      return k + ':' + styles[k] + unit + ';'
    }).join('')
} ;
__p += '\n\n';
 function dateDiff(start, end) {
  start = new Date(start)
  var startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  if (!end) {
    end = new Date();
  }
  end = new Date(end)
  // need date in YYYY-MM-DD format
  var endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  if (startDate > endDate) {
    return null
  }

  var startYear = startDate.getFullYear();

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    var year = endDate.getFullYear()
    var february = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var lastMonth = endDate.getMonth() - 1
    if (lastMonth < 0) {
      lastMonth = 11
    }
    dayDiff += daysInMonth[lastMonth];
  }

  var oneDay = 86400000
  if (endDate - startDate < oneDay * 100) {
    dayDiff = Math.ceil((endDate - startDate) / oneDay)
    dayDiff++
    if (dayDiff === 1) {
      return '出生啦'
    }
    if (dayDiff === 100) {
      return '100天啦'
    }
    return dayDiff + '天'
  }

  if (yearDiff > 0 && monthDiff === 0 && dayDiff === 0) {
    return yearDiff + '岁啦'
  }

  if (yearDiff > 0) {
    dayDiff = 0
  }

  return [
    yearDiff ? yearDiff + '岁' : '',
    monthDiff ? monthDiff + '个月' : '',
    dayDiff ? dayDiff + '天' : ''
  ].join('')
} ;
__p += '\n\n';
 function ifTextByChars(elements) {
  return JSON.stringify(elements).indexOf('"x":[') > -1
} ;
__p += '\n\n';
 for (var k = 0, _len = element.content.length; k < _len; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var isFirst = k === 0 && !element.pre ;
__p += '\n  ';
 var isLast = k === element.content.length - 1 && !element.post || line.last ||
    line.text[line.text.length - 1] === '\n' ;
__p += '\n  ';
 var text = line.text ;
__p += '\n  ';
 text = text.replace(/</g, '&lt;') ;
__p += '\n  ';
 text = text.replace(/>/g, '&gt;') ;
__p += '\n  ';
 text = text.replace(/\[em](.+?)\[\/em]/gi, function ($0, $1) {
    var url = 'https://static.weixinshu.com/assets/emotions/' + $1
    if (/^http/.test($1)) {
      url = $1
    }
    return '<img src="' + url + '" class="emoji">'
  })
  ;
__p += '\n  ';
 var classList = ['text'] ;
__p += '\n  ';
 if (isFirst) classList.push('first') ;
__p += '\n  ';
 if (isLast) classList.push('last') ;
__p += '\n  ';
 line.x = line.x || line.startX || element.x ;
__p += '\n  ';
 line.w = line.w || line.width || element.width ;
__p += '\n  ';
 var style = getStyle({top: line.y, left: line.x, width: line.w}) ;
__p += '\n\n  <div class="' +
((__t = ( classList.join(' ') )) == null ? '' : __t) +
'" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">' +
((__t = ( text )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n\n      ';
 }) ;
__p += '\n    </div>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n\n<footer>\n  <div class="date">' +
((__t = ( year + '.' + month )) == null ? '' : __t) +
'</div>\n  <div class="pagination">' +
((__t = ( pageNo )) == null ? '' : __t) +
'</div>\n</footer>\n';

}
return __p
}