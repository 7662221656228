module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 for (var k = 0; k < element.content.length; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 if (line.text) { ;
__p += '\n    ';
 var fontSize = element.fontSize || 14 ;
__p += '\n    ';
 var y = line.y + fontSize ;
__p += '\n    <g class="text ' +
((__t = ( line.isComment ? 'comments' : '' )) == null ? '' : __t) +
'"\n       style="font-size: ' +
((__t = ( fontSize )) == null ? '' : __t) +
'px"\n       data-msg-id="' +
((__t = ( element.msgId || element.pid || element.id )) == null ? '' : __t) +
'">\n      ';
 if (line.commentStat) { ;
__p += '\n        ';
 var thumbsCount = line.commentStat.split(',')[0] ;
__p += '\n        ';
 var commentsCount = line.commentStat.split(',')[1] ;
__p += '\n        ';
 var offset = thumbsCount.toString().length * fontSize + fontSize ;
__p += '\n        <image x="' +
((__t = ( line.x[0] )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y + 1 )) == null ? '' : __t) +
'"\n               width="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'"\n               xlink:href="https://img.xinshu.me/upload/84fb37deb2f74c6a8c65d95e3a5180fd"/>\n        <text class="text theme"\n              x="' +
((__t = ( line.x[0] + fontSize * 1.5 )) == null ? '' : __t) +
'"\n              y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( thumbsCount )) == null ? '' : __t) +
'</text>\n        <image x="' +
((__t = ( line.x[0] + fontSize + offset )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y + 1 )) == null ? '' : __t) +
'"\n               width="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 2 )) == null ? '' : __t) +
'"\n               xlink:href="https://img.xinshu.me/upload/12cee2aa10c3474b83830ed1341ed893"/>\n        <text class="text theme"\n              x="' +
((__t = ( line.x[0] + fontSize * 2.5 + offset )) == null ? '' : __t) +
'"\n              y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( commentsCount )) == null ? '' : __t) +
'</text>\n      ';
 } else { ;
__p += '\n        ';
 var chars = ('from' in Array) ? Array.from(line.text) : line.text ;
__p += '\n        ';
 for (var l = 0; l < chars.length; l++) { ;
__p += '\n          ';
 var emoji = line.emoji ? line.emoji[l] : null ;
__p += '\n          ';
 if (emoji) { ;
__p += '\n            ';
 var link = emoji.replace(/^http:/, 'https:') ;
__p += '\n            ';
 if (!/^https?:/.test(link)) {
              link = 'https://static.weixinshu.com/assets/emotions/' + link
            } ;
__p += '\n            <image x="' +
((__t = ( line.x[l] )) == null ? '' : __t) +
'" y="' +
((__t = ( line.y )) == null ? '' : __t) +
'"\n                   width="' +
((__t = ( fontSize + 4 )) == null ? '' : __t) +
'" height="' +
((__t = ( fontSize + 4 )) == null ? '' : __t) +
'"\n                   xlink:href="' +
((__t = ( link )) == null ? '' : __t) +
'"/>\n            ';
 continue ;
__p += '\n          ';
 } ;
__p += '\n          ';
 if (chars[l] && chars[l] !== ' ') { ;
__p += '\n            ';
 var x = line.x[l], char = chars[l] ;
__p += '\n            ';
 var classes = [] ;
__p += '\n            ';
 if (l <= line.commenterX) {
              classes.push('theme')
            } ;
__p += '\n            ';
 var classNames = classes.join(' ') ;
__p += '\n            <text class="' +
((__t = ( classNames )) == null ? '' : __t) +
'" x="' +
((__t = ( x )) == null ? '' : __t) +
'" y="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( char )) == null ? '' : __t) +
'</text>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </g>\n  ';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}