module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 var tzOffset = dateTime.getTimezoneOffset() ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (tzOffset + 480) * 60 * 1000) ;
__p += '\n';
 var week = dateTime.getDay() ;
__p += '\n';
 var weeks = ['日', '一', '二', '三', '四', '五', '六'] ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 var style = getStyle({top: element.y, left: element.x, width: element.w}) ;
__p += '\n<div style="' +
((__t = ( style )) == null ? '' : __t) +
'" class="label">\n  <span class="date">' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</span>\n  <span class="day">周' +
((__t = ( weeks[week] )) == null ? '' : __t) +
'</span>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}