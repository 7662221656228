module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (dateTime.getTimezoneOffset() + 480) * 60000) ;
__p += '\n';
 var birthday = new Date(book.style.baby.birthday) ;
__p += '\n';
 birthday = new Date(birthday.getTime() + (dateTime.getTimezoneOffset() + 480) * 60000) ;
__p += '\n';
 var week = dateTime.getDay() ;
__p += '\n';
 var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'] ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 var style = getStyle({top: element.y, left: element.x, width: element.w}) ;
__p += '\n';
 var days = dateDiff(birthday, dateTime);
__p += '\n<div style="' +
((__t = ( style )) == null ? '' : __t) +
'" class="label label-baby" data-content="' +
((__t = ( element.content )) == null ? '' : __t) +
'">\n  ';
 if (days) { ;
__p += '\n    <div class="days">\n      <span class="before"></span>\n      <span class="content">\n        ' +
((__t = ( book.style.baby.nickname + days )) == null ? '' : __t) +
'\n      </span>\n      <span class="after"></span>\n    </div>\n  ';
 } ;
__p += '\n  <div class="board">\n    <span class="date">' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</span>\n    <span class="day">' +
((__t = ( weeks[week] )) == null ? '' : __t) +
'</span>\n  </div>\n  <span class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}