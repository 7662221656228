<template>
  <div class="page preface-page"
       :class="[book.type, {locked: !book.editable, empty: !book.preface}]"
       id="preface">
    <div class="html" v-html="html"></div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'prefacePage',
  props: {
    book: {
      type: Object,
      required: true
    },
    readonly: Boolean
  },
  data() {
    return {
      html: ''
    }
  },
  created() {
    if (!this.book.preface) {
      return
    }
    const typeset = this.book.type.split('-')[0].toLowerCase()
    const preface = this.book.style.preface || 'default'
    const templateName = 'wxbook-' + preface + '.preface-' + typeset
    const [content, signature] = this.book.preface.split('#signature#')
    const params = {
      size: 800,
      content,
      signature
    }

    if (window.canUseWebp) {
      params.webp = 1
    }

    const url = 'https://canvas.xinshu.me/generate/html/' + templateName
    this.$req.post(url, params).then(html => {
      this.html = html
    })
  }
}
</script>

<style scoped lang="scss">
.preface-page {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.empty {
    text-align: center;
    font-size: 1.5em;
    height: auto;
    cursor: pointer;
    background-image: none;
    display: block;
    padding: 2em;
    @include media-breakpoint-down(sm) {
      font-size: 2em;
    }

    .content {
      color: $primary;
    }
  }

  &.empty.locked {
    display: none !important;
  }

  &.locked {
    cursor: default;
  }

  .html {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
</style>
