<template>
  <div class="page content"
       :class="[page.pageNo % 2 ? 'even' : 'odd']"
       :style="style"
       :data-parity="page.pageId % 2 ? 'even' : 'odd'"
       :data-month="month" :data-id="page.pageNo" v-html="innerHTML"></div>
</template>

<script>
import { forEach, get, toLower } from 'lodash'
import { blankPic } from '@/config'

export default {
  name: 'contentPage',
  props: {
    page: {
      required: true,
      type: Object
    },
    date: String,
    load: Boolean,
    book: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      month: '',
      innerHTML: ''
    }
  },
  events: {
    pageLoad(val) {
      if (Number(val) === Number(this.page.pageNo)) {
        this.loadPage()
      }
    }
  },
  mounted() {
    if (this.load) {
      this.loadPage()
    }
    this.$watch('page', () => {
      this.loadPage()
    })
  },
  computed: {
    style() {
      const footer = get(this.book, 'style.pageFooter')
      const type = get(this.book, 'type')
      const style = {}
      const even = this.page.pageId % 2
      if (!/child|home/.test(type) && footer && footer !== 'default') {
        const url = `https://canvas.xinshu.me/generate/footer-${footer}.${even ? 'right' : 'left'}?size=800`
        style['background-image'] = `url('${url}')`
      }
      return style
    }
  },
  methods: {
    loadPage() {
      const data = {
        book: this.book,
        page: this.page,
        pageNo: this.page.pageNo,
        year: this.date.slice(0, -2),
        month: this.date.slice(-2)
      }

      const style = this.book.pageStyle || this.book.style || {}
      if (!style.text) {
        style.text = {fontSize: 14}
      }

      data.pageFont = style.text

      for (const el of data.page.elements) {
        el.type = el.type || 'text'
        if (el.style) {
          Object.assign(el, this.parseStyle(el.style))
        }
        for (const field of ['title', 'subtitle', 'note', 'text']) {
          if (el.type === field) {
            el.fontSize = el.fontSize || style[field]?.fontSize
          }
        }
      }

      this.month = parseInt(data.month)
      if (this.book.bookType === 'blogbook') {
        this.month = data.month
      }

      this.$el.classList.add('loaded')
      const compiled = require('@/assets/templates/' + toLower(this.book.type) + '.ejs')
      this.innerHTML = compiled(data)
      this.$nextTick(this.loadImages)
    },
    loadImages() {
      forEach(this.$el.querySelectorAll('[lazy-load]'), (el) => {
        if (el.tagName === 'image') {
          this.handleSVGImage(el)
        } else {
          this.handleHTMLImg(el)
        }
      })
    },
    async handleHTMLImg(el) {
      try {
        el.style.backgroundColor = '#ebebeb'
        const url = el.getAttribute('lazy-load')
        await this.loadImage(url)
        el.style.backgroundColor = null
        el.style.backgroundImage = `url('${url}')`
        el.removeAttribute('lazy-load')
        el.classList.add('loaded')
      } catch (err) {
        console.warn(err)
        el.style.backgroundImage = `url(${require('@/assets/images/error.jpg')})`
        el.style.backgroundSize = 'cover'
      }
    },
    async handleSVGImage(el) {
      try {
        el.setAttribute('href', blankPic)
        el.setAttribute('preserveAspectRatio', 'xMidYMid slice')
        const url = el.getAttribute('lazy-load')
        await this.loadImage(url)
        el.setAttribute('href', url)
        el.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', url)
        el.removeAttribute('lazy-load')
        el.classList.add('loaded')
      } catch (err) {
        console.warn(err)
        el.setAttribute('href', require('@/assets/images/error.jpg'))
      }
    },
    parseStyle(styleStr) {
      const style = {}
      const styleIdentifiers = {
        color: /rgb\(|#\w+/,
        fontSize: /\dpx/,
        fontWeight: /bold|semibold/i,
        fontStyle: /italic/
      }
      String(styleStr).split(';').forEach(item => {
        for (const [field, tester] of Object.entries(styleIdentifiers)) {
          if (tester.test(item)) {
            style[field] = item
          }
        }
      })
      return style
    }
  },
  beforeDestroy() {
    this.innerHTML = ''
  }
}
</script>

<style scoped lang="scss">
.page.content {
  background-size: cover;
  background-position: bottom center;
}
</style>
