module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<rect data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'" class="block"\n      x="' +
((__t = ( element.x )) == null ? '' : __t) +
'" y="' +
((__t = ( element.y )) == null ? '' : __t) +
'"\n      width="' +
((__t = ( element.w )) == null ? '' : __t) +
'" height="' +
((__t = ( element.h )) == null ? '' : __t) +
'"\n      fill="transparent"></rect>\n<path d="M ' +
((__t = ( element.x + element.w - 10 )) == null ? '' : __t) +
' ' +
((__t = ( element.y + 1 )) == null ? '' : __t) +
'\n               L ' +
((__t = ( element.x + element.w )) == null ? '' : __t) +
' ' +
((__t = ( element.y + 1 )) == null ? '' : __t) +
'\n               L ' +
((__t = ( element.x + element.w )) == null ? '' : __t) +
' ' +
((__t = ( element.y + 10 + 1 )) == null ? '' : __t) +
'" stroke="#aaa" stroke-width="2"\n      fill="transparent"></path>\n<path d="M ' +
((__t = ( element.x + element.w - 10 )) == null ? '' : __t) +
' ' +
((__t = ( element.y + element.h )) == null ? '' : __t) +
'\n              L ' +
((__t = ( element.x + element.w )) == null ? '' : __t) +
' ' +
((__t = ( element.y + element.h )) == null ? '' : __t) +
'\n              L ' +
((__t = ( element.x + element.w )) == null ? '' : __t) +
' ' +
((__t = ( element.y + element.h - 10 )) == null ? '' : __t) +
'" stroke="#aaa" stroke-width="2"\n      fill="transparent"></path>\n';

}
return __p
}