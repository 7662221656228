import { decodeBookParams, getCoverUrl } from '@/models/book'

export default {
  computed: {
    bookParams() {
      // 优先获取 url 中的 bookId
      // 没有上面的字段则使用作品信息中的 bookId
      return decodeBookParams(this.bookId)
    },

    bookId() {
      return this.book.bookId || this.$route.params.bookId
    },

    bookType() {
      const types = {
        shuoshuo_book: 'QQ空间',
        wxbook: '朋友圈',
        wbbook: '微博',
        diarybook: '日记书'
      }
      return types[this.book.bookType]
    },
    coverUrl() {
      return getCoverUrl(this.book)
    },
    bookCover() {
      return `https://canvas.xinshu.me/generate/share-icon?pic=${encodeURIComponent(this.coverUrl)}`
    }
  }
}
