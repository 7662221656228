<template>
  <div class="page acknowledgement-page"
       :class="[{empty: !book.acknowledgement, locked: !book.editable}, book.type]"
       id="acknowledgement">
    <div class="html" v-html="html"></div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'acknowledgementPage',
  props: {
    book: {
      type: Object,
      required: true
    },
    readonly: Boolean
  },
  data() {
    return {
      html: ''
    }
  },
  created() {
    if (this.book.acknowledgement) {
      this.getPage()
    }
  },
  methods: {
    getPage() {
      const typeset = this.book.type.split('-')[0].toLowerCase()
      const acknowledgement = this.book.style.acknowledgement || 'default'
      const templateName = 'wxbook-' + acknowledgement + '.acknowledgement-' + typeset
      const params = {
        size: 800,
        content: this.book.acknowledgement
      }
      if (window.canUseWebp) {
        params.webp = 1
      }
      const url = 'https://canvas.xinshu.me/generate/html/' + templateName
      return this.$req.post(url, params).then(html => {
        this.html = html
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .acknowledgement-page {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover .mask {
      opacity: 1;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      font-size: 2em;
      cursor: pointer;
      background-color: rgba(0, 0, 0, .3);
      opacity: 0;
      transition: opacity .3s;
    }

    &.empty {
      text-align: center;
      font-size: 1.5em;
      height: auto;
      cursor: pointer;
      background-image: none;
      display: block;
      padding: 2em;
      @include media-breakpoint-down(sm) {
        font-size: 2em;
      }

      .content {
        color: $primary;
      }
    }

    &.locked {
      cursor: default;
    }

    &.empty.locked {
      display: none !important;
    }

    .html {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }
</style>
