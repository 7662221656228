<template>
  <div class="page cover-page">
    <cover :book="book" :size="size" loader/>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'coverPage',
  props: {
    book: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      size: 640
    }
  },
  created() {
    if (/A4/i.test(this.book.type)) {
      this.size = 800
    }
  }
}
</script>

<style scoped lang="scss">
.cover-page {
  padding: 0;

  .cover {
    height: 100%;
  }
}
</style>
