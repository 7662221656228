module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var dateTime = new Date(element.content * 1000); ;
__p += '\n';
 var tzOffset = dateTime.getTimezoneOffset() ;
__p += '\n';
 dateTime = new Date(element.content * 1000 + (tzOffset + 480) * 60 * 1000) ;
__p += '\n';
 var time = ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2); ;
__p += '\n';
 if (element.type === 'label-d') { ;
__p += '\n  <div class="label" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'"\n       style="top: ' +
((__t = ( yOrigin + element.y )) == null ? '' : __t) +
'px;left:' +
((__t = ( xOrigin )) == null ? '' : __t) +
'px;">\n    <div class="date">' +
((__t = ( ('0' + (dateTime.getMonth() + 1)).slice(-2) )) == null ? '' : __t) +
'.' +
((__t = ( ('0' + dateTime.getDate()).slice(-2) )) == null ? '' : __t) +
'</div>\n    <div class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</div>\n  </div>\n';
 } ;
__p += '\n';
 if (element.type === 'label-t') { ;
__p += '\n  <div class="label" style="top: ' +
((__t = ( yOrigin + element.y )) == null ? '' : __t) +
'px;left:' +
((__t = ( xOrigin )) == null ? '' : __t) +
'px;">\n    <div class="time">' +
((__t = ( time )) == null ? '' : __t) +
'</div>\n  </div>\n';
 } ;
__p += '\n';

}
return __p
}