<template>
  <div class="page content catalog-page">
    <div class="page-inner">
      <div v-if="isFirst" class="title">
        <img src="https://img.xinshu.me/upload/a242ba1634724c45b9b36c2e17a12c0e" alt="">
      </div>
      <div :style="{fontSize: fontSize + 'px'}" v-html="innerHTML"></div>
    </div>
    <footer :style="{fontSize: fontSize + 'px'}">C{{content.pageId + 1}}</footer>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'catalogPage',
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default() {
        return {}
      }
    },
    book: {
      type: Object
    }
  },
  data() {
    return {
      innerHTML: ''
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick)
    this.innerHTML = ''
  },
  computed: {
    fontSize() {
      return this.book?.pageStyle?.catalog?.fontSize
    }
  },
  mounted() {
    const data = {
      page: this.content
    }
    const compiled = require('@/assets/templates/a5-blog.ejs')
    this.innerHTML = compiled(data)
    document.addEventListener('click', this.onClick)
  },
  methods: {
    async onClick(e) {
      if (!e.target || !('closest' in e.target.closest)) {
        return
      }
      const msgId = get(e.target, 'dataset.msgId') ||
        get(e.target.closest('[data-msg-id]'), 'dataset.msgId')
      if (msgId) {
        const {bookId} = this.$route.params
        this.$router.push(`./${bookId}/months/${msgId}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  float: none !important;
}
.catalog-page {
  .page-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 100px;

    .title {
      margin-top: 3rem;
      margin-bottom: 3rem;
      height: 4rem;

      img {
        height: 100%;
        display: block;
        margin-left: auto;
      }
    }

    .content {
      .item {
        display: block;
        text-align: left;
        margin-bottom: 0.6rem;

        .pagination {
          float: right;
          padding-left: 1em;
        }
      }
    }
  }

  footer {
    text-align: center;
  }
}
</style>
