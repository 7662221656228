module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 function getStyle(styles) {
  return Object
    .keys(styles)
    .filter(function (k) {
      return styles[k]
    })
    .map(function (k) {
      var unit = ''
      if (['top', 'left', 'width', 'height'].indexOf(k) > -1) {
        unit = 'px'
      }
      return k + ':' + styles[k] + unit + ';'
    }).join('')
} ;
__p += '\n\n';
 function dateDiff(start, end) {
  start = new Date(start)
  var startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  if (!end) {
    end = new Date();
  }
  end = new Date(end)
  // need date in YYYY-MM-DD format
  var endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  if (startDate > endDate) {
    return null
  }

  var startYear = startDate.getFullYear();

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    var year = endDate.getFullYear()
    var february = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var lastMonth = endDate.getMonth() - 1
    if (lastMonth < 0) {
      lastMonth = 11
    }
    dayDiff += daysInMonth[lastMonth];
  }

  var oneDay = 86400000
  if (endDate - startDate < oneDay * 100) {
    dayDiff = Math.ceil((endDate - startDate) / oneDay)
    dayDiff++
    if (dayDiff === 1) {
      return '出生啦'
    }
    if (dayDiff === 100) {
      return '100天啦'
    }
    return dayDiff + '天'
  }

  if (yearDiff > 0 && monthDiff === 0 && dayDiff === 0) {
    return yearDiff + '岁啦'
  }

  if (yearDiff > 0) {
    dayDiff = 0
  }

  return [
    yearDiff ? yearDiff + '岁' : '',
    monthDiff ? monthDiff + '个月' : '',
    dayDiff ? dayDiff + '天' : ''
  ].join('')
} ;
__p += '\n\n';
 function ifTextByChars(elements) {
  return JSON.stringify(elements).indexOf('"x":[') > -1
} ;
__p += '\n\n';
 for (var k = 0, _len = element.content.length; k < _len; k++) { ;
__p += '\n  ';
 var line = element.content[k] ;
__p += '\n  ';
 var isFirst = k === 0 && !element.pre ;
__p += '\n  ';
 var isLast = k === element.content.length - 1 && !element.post || line.last ||
    line.text[line.text.length - 1] === '\n' ;
__p += '\n  ';
 var text = line.text ;
__p += '\n  ';
 text = text.replace(/</g, '&lt;') ;
__p += '\n  ';
 text = text.replace(/>/g, '&gt;') ;
__p += '\n  ';
 text = text.replace(/\[em](.+?)\[\/em]/gi, function ($0, $1) {
    var url = 'https://static.weixinshu.com/assets/emotions/' + $1
    if (/^http/.test($1)) {
      url = $1
    }
    return '<img src="' + url + '" class="emoji">'
  })
  ;
__p += '\n  ';
 var classList = ['text'] ;
__p += '\n  ';
 if (isFirst) classList.push('first') ;
__p += '\n  ';
 if (isLast) classList.push('last') ;
__p += '\n  ';
 line.x = line.x || line.startX || element.x ;
__p += '\n  ';
 line.w = line.w || line.width || element.width ;
__p += '\n  ';
 var style = getStyle({top: line.y, left: line.x, width: line.w}) ;
__p += '\n\n  <div class="' +
((__t = ( classList.join(' ') )) == null ? '' : __t) +
'" data-msg-id="' +
((__t = ( element.msgId )) == null ? '' : __t) +
'"\n       style="' +
((__t = ( style )) == null ? '' : __t) +
'">' +
((__t = ( text )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';

}
return __p
}