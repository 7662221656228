<template>
  <div class="page title-page">
    <square :src="image" :height="book.type" radius="0"/>
  </div>
</template>

<script>
export default {
  name: 'titlePage',
  mixins: [
    require('@/mixins/book-utils').default
  ],
  props: {
    book: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: 0
    }
  },
  computed: {
    image() {
      const titlePageUrl = this.book?.style?.titlePage

      if (titlePageUrl) {
        return titlePageUrl.split('!')[0] + '!' + (window.canUseWebp ? 'webp' : '') + '1280'
      }

      const style = this.book?.style?.title || 'default'
      // 加一天防止出现时区问题
      const from = this.$day(this.book.fromDate).add(1, 'day').format('YYYY.MM')
      const to = this.$day(this.book.toDate).add(1, 'day').format('YYYY.MM')

      let name = 'wxbook-' + style + '.title'
      const vendor = this.book.vendor || this.book?.agentInfo?.supplementary?.vendor || '心书'

      if (this.book.bookType === 'blogbook') {
        name = 'wxbook-default.title-blog'
      }

      let url = 'https://canvas.xinshu.me/generate/' + name

      const query = {
        from: 'From: ' + from,
        to: 'To: ' + to,
        author: this.book.author,
        size: this.book.type === 'A4' ? 800 : 640,
        vendor
      }

      url += this.serialize(query)

      return url
    }
  }
}
</script>

<style scoped lang="scss">
  .title-page {
    padding: 0;

    img {
      width: 100%;
      height: 100%;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2em;
      margin: auto;
    }
  }
</style>
